import {RouterConfiguration, Router, NavigationInstruction, Next, Redirect} from 'aurelia-router';
import {AppConfig} from 'appconfig';
import {viewResources, PLATFORM, bindable} from 'aurelia-framework';
import {DialogContainer} from 'elements/dialog/container';

@viewResources(
	PLATFORM.moduleName('elements/header'),
	PLATFORM.moduleName('elements/dialog/container')
)
export class App {
	@bindable public readonly dialogContainer: DialogContainer;

	private routerLoaded: (Router) => void;
	private isDragging: boolean = false;
	public router: Router;
	public loadRouter: Promise<Router> = new Promise((resolve) => {
		this.routerLoaded = resolve;
	});
	public config: typeof AppConfig = AppConfig;
	public currentRoute: string;

	constructor() {
		document.addEventListener('dragover', (event) => {
			if (event.dataTransfer.items[0].kind !== 'file') return;
			this.isDragging = true;
			event.dataTransfer.effectAllowed = 'none';
			event.dataTransfer.dropEffect = 'none';
			event.preventDefault();
		});
		document.addEventListener('dragenter', (event) => {
			if (event.dataTransfer.items[0].kind !== 'file') return;
			this.isDragging = true;
		});
		document.addEventListener('dragleave', () => {
			this.isDragging = false;
		});
		document.addEventListener('drop', (event) => {
			this.isDragging = false;
			event.preventDefault();
		});
	}

	public configureRouter(routerConfig: RouterConfiguration, router: Router) {
		const app = this;
		this.router = router;
		routerConfig.title = AppConfig.title + ' Admin';
		routerConfig.options.pushState = true;
		routerConfig.options.root = '/';
		routerConfig.addAuthorizeStep({
			run(navInstruction: NavigationInstruction, next: Next): Promise<any> {
				if (AppConfig.auth) {
					if (navInstruction.config.name === 'signin') return next.cancel(new Redirect(''));
				} else {
					if (navInstruction.config.name !== 'signin') {
						app.config.signinRedirect = navInstruction;
						return next.cancel(new Redirect('signin'));
					}
				}
				return next();
			}
		});
		routerConfig.addPreRenderStep({
			run(navInstruction: NavigationInstruction, next: Next): Promise<any> {
				app.config.navIsOpen = false;
				app.currentRoute = navInstruction.config.name;
				return next();
			}
		});
		routerConfig.map(AppConfig.routes.map((route) => route.config));
		this.routerLoaded(this.router);
	}
}
