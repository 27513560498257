import {observable} from 'aurelia-binding';
import {bindable} from 'aurelia-framework';
import {Column} from './column';
import {ItemTable} from '.';
import {escapeRegExp} from '../../utils';

export interface SearchConfig {
	placeholder?: string;
	columns?: string[];
	onEnter?: (this: Search) => string|void;
}

export class Search {
	@observable @bindable public query: string;
	public readonly placeholder: string;
	public readonly columns: Column[];
	public autofocus: boolean = true;

	constructor(private readonly table: ItemTable, config?: SearchConfig) {
		this.columns = config && config.columns ? config.columns.map((id) => table.columns.find((column) => column.valueProperty === id)) : table.columns;
		this.placeholder = config && config.placeholder || this.columns.map((column) => column.title).join(', ');
		if (config && config.onEnter) this.onEnter = config.onEnter;
	}

	public execute(): Promise<void> {
		const start = performance.now();
		const {items} = this.table;

		return (new Promise((resolve) => {
			if (!this.query || !this.query.trim()) {
				for (const item of items) {
					if (item.isHidden) item.hiddenElement.replaceWith(item.element);
					item.isHidden = false;
					//item.element.classList.remove('hidden');
					for (const {valueProperty} of this.columns) item.values[valueProperty].resetText();
				}
				this.table.itemCount = items.length;
				return resolve();
			}

			this.table.itemCount = 0;

			const query = this.query.trim().replace(/\s+/g, ' ').trim().split(' ').map((part) => escapeRegExp(part));
			const anyPart = new RegExp(query.join('|'), 'gi');

			const span = document.createElement('span');
			function convert(text: string) {
				span.innerText = text;
				return span.innerHTML;
			}

			for (const item of items) {

				// Get the current hidden state for use later
				const wasHidden = item.isHidden;

				// If the item doesn't contain usable values, hide the item
				item.isHidden = !this.columns.find((column) => {
					return !!item.values[column.valueProperty].value;
				});

				// If not already hidden, and there is no match for any of the queried search terms, hide the item
				if (!item.isHidden) {
					item.isHidden = !!query.find((part) => {
						return !this.columns.find(({valueProperty}) => {
							return (new RegExp(part, 'gi')).test(item.values[valueProperty].normalizedValue);
						});
					});
				}

				// Toggle the hidden state of the element if necessary by replacing with a placeholder span
				if (item.isHidden && !wasHidden) {
					item.element.replaceWith(item.hiddenElement);
				} else if (!item.isHidden && wasHidden) {
					item.hiddenElement.replaceWith(item.element);
				}

				//item.element.classList.toggle('hidden', item.isHidden);
				
				if (!item.isHidden) this.table.itemCount++;

				// Loop through the columns to apply search term highlighting
				for (const {valueProperty} of this.columns) {
					const itemValue = item.values[valueProperty];
					const {value, normalizedValue} = itemValue;

					anyPart.lastIndex = 0;
					if (item.isHidden || !anyPart.test(normalizedValue)) {
						itemValue.resetText();
						continue;
					}

					let displayValue = '';
					let match: RegExpExecArray;
					let prevIndex: number = 0;
					anyPart.lastIndex = 0;

					while (match = anyPart.exec(normalizedValue)) {
						displayValue += convert(value.substring(prevIndex, match.index));
						displayValue += `<b>${convert(value.substring(match.index, anyPart.lastIndex))}</b>`;
						prevIndex = anyPart.lastIndex;
					}
					displayValue += convert(value.substring(prevIndex));
					itemValue.setHtml(displayValue);
				}

			}

			resolve();
		})).then(() => {
			if (!this.table.holdBusy) this.table.isBusy = false;
			console.info(`Search for "${this.query}" completed in ${performance.now() - start}ms`);
		});
	}
	
	private queryChanged(): void {
		this.table.isBusy = true;
		this.table.status = 'Searching';
		setTimeout(() => {
			this.execute();
		}, 0);
	}

	private onKey(event: KeyboardEvent): boolean {
		if (this.onEnter && event.key === 'Enter') this.onEnter();
		return true;
	}

	private onEnter(): void {
		if (!this.query || this.table.itemCount !== 1) return;
		const item = this.table.items.find((item) => !item.isHidden);
		if (!item || !this.table.createLinks) return;
		window.open(item.element.href);
		this.query = '';
	}
}
