import {DatePicker} from ".";
import {DateFormatter} from "dateFormatter";

export class Month {
	public readonly year: number;
	public readonly month: number;
	public readonly name: string;
	private readonly days: Day[] = [];
	private readonly weeks: Week[] = [];

	constructor(public readonly datePicker: DatePicker, year: number, month: number) {
		let date = new Date(year, month, 1);

		this.year = date.getFullYear();
		this.month = date.getMonth();
		this.name = `${DateFormatter.months[this.month]} ${this.year}`;

		let week: Week;
		while (date.getMonth() === this.month) {
			const day = new Day(this, date);

			if (!week || !date.getDay()) {
				week = new Week();
				this.weeks.push(week);
			}

			week.add(day);
			this.days.push(day);

			date.setDate(date.getDate() + 1);
		}
		week.fill();
	}

	public refresh(): void {
		for (const day of this.days) day.refresh();
	}
}

class Week {
	private readonly days: Day[] = [];

	public add(day: Day): void {
		const index = day.date.getDay();
		if (!this.days.length) for (let i = 0; i < index; i++) this.days.push(null);
		this.days.push(day);
	}

	public fill(): void {
		while (this.days.length < 7) this.days.push(null);
	}
}

class Day {
	public readonly date: Date;
	public readonly number: number;
	private cssClass: string = '';

	constructor(private readonly month: Month, date: Date) {
		this.date = new Date(date);
		this.number = this.date.getDate();
		this.refresh();
	}

	private select(): void {
		this.month.datePicker.date.setMonth(this.month.month, this.number);
		this.month.datePicker.dateChanged();
	}

	public refresh(): void {
		const {now, date} = this.month.datePicker;
		const classes = [];
		if (date && date.getDate() === this.number && date.getMonth() === this.month.month && date.getFullYear() === this.month.year) classes.push('active');
		if (now.getDate() === this.number && now.getMonth() === this.month.month && now.getFullYear() === this.month.year) classes.push('today');
		this.cssClass = classes.join(' ');
	}

	public toString(): string {
		return this.number.toString();
	}
}
