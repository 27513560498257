import {bindable, viewResources, PLATFORM, observable} from "aurelia-framework";
import {getSubset} from "utils";

interface DropdownOptionConfig {
	isHidden?: boolean;
	isDisabled?: boolean;
	isDefault?: boolean;
	link?: string;
	onSelect?: (this: DropdownOption) => void;
	data?: any;
}
export class DropdownOption implements DropdownOptionConfig {
	public element: HTMLAnchorElement;
	public dropdown?: Dropdown;
	public isSelected: boolean = false;
	public isHidden: boolean = false;
	public isDisabled: boolean = false;
	public isFiltered: boolean = false;
	public normalizedLabel?: string;
	public readonly isDefault: boolean = false;
	public readonly link?: string;
	public readonly onSelect?: (this: DropdownOption) => void;
	public readonly data: any;

	constructor(public readonly label: string, config?: DropdownOptionConfig) {
		Object.assign(this, config);
	}

	public select(): boolean {
		if (this.isDisabled) return false;
		if (this.link) return true;
		if (this.dropdown) return this.dropdown.selectOption(this);
		return false;
	}
}

export class DropdownSeparator extends DropdownOption {
	constructor() {
		super('', {isDisabled: true});
	}
}

interface DropdownConfigParams {
	isSelectable?: boolean;
	isSearchable?: boolean;
	sort?: boolean;
	selectedOption?: string;
	onChange?: (this: Dropdown, selectedOption: DropdownOption) => void;
	onCreate?: (this: DropdownConfig, dropdown: Dropdown) => void;
}

export class DropdownConfig implements DropdownConfigParams {
	public readonly isSelectable: boolean = true;
	public readonly isSearchable: boolean = false;
	public readonly sort: boolean;
	public readonly selectedOption?: string;
	public readonly onChange?: DropdownConfigParams['onChange'];
	public readonly onCreate?: DropdownConfigParams['onCreate'];

	constructor(public options: DropdownOption[], params?: DropdownConfigParams) {
		if (params) Object.assign(this, params);
		if (this.sort === undefined) this.sort = this.isSearchable;
	}
}

class DropdownSearch {
	@observable private query: string = '';
	public element: HTMLInputElement;
	private firstMatch?: DropdownOption;

	constructor(private readonly dropdown: Dropdown) {}

	private queryChanged(value: string, pValue: string): void {
		value = value && value.trim().toLowerCase();
		pValue = pValue && pValue.trim().toLowerCase();
		if (value === undefined || pValue === undefined || value === pValue) return;

		const query = this.query.trim().replace(/\s+/g, ' ').trim().split(' ');
		let foundMatch = false;
		for (const option of this.dropdown.visibleOptions) {
			if (!value) {
				option.isFiltered = false;
				continue;
			}
			option.isFiltered = !!query.find((term) => !option.normalizedLabel.includes(term));
			if (!foundMatch && !option.isFiltered) {
				foundMatch = true;
				if (this.firstMatch !== option) {
					this.clearMatch();
					this.firstMatch = option;
					option.element.classList.add('focus');
					this.dropdown.scrollTo(0);
				}
			}
		}
		if (!foundMatch) {
			this.clearMatch();
			setTimeout(() => {
				this.dropdown.scrollTo(this.dropdown.selectedOption);
			}, 0);
		}
	}
	private onKey(event: KeyboardEvent): boolean {
		if (event.key !== 'Enter') return true;
		if (!this.firstMatch) return false;
		this.firstMatch.select();
	}
	private onFocus(event: Event): void {
		event.stopPropagation();
		event.preventDefault();
	}
	private clearMatch(): void {
		if (!this.firstMatch) return;
		this.firstMatch.element.classList.remove('focus');
		delete this.firstMatch;
	}
	public clear(): void {
		this.query = '';
		this.clearMatch();
	}
}

@viewResources(PLATFORM.moduleName('elements/dropdown/dropdownitem'))
export class Dropdown {
	@bindable private readonly config: DropdownConfig;
	@bindable private readonly element: HTMLElement;
	@bindable private readonly innerElement: HTMLDivElement;
	@bindable private readonly listElement: HTMLUListElement;
	private readonly options: DropdownOption[];
	private readonly onChange?: (this: Dropdown, selectedOption: DropdownOption) => void;
	private isOpen: boolean = false;
	private closeListener: () => void;
	private focusListener: (event: FocusEvent) => void;
	private justFocused: boolean = false;
	private focusedOption?: DropdownOption;
	private search?: DropdownSearch;
	public visibleOptions: DropdownOption[];
	public selectedOption: DropdownOption;

	private attached(): void {
		Object.assign(this, getSubset(this.config, 'options', 'onChange'));
		const visibleOptions = [];
		for (const option of this.options) {
			option.dropdown = this;
			option.normalizedLabel = option.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
			if (!option.isHidden) visibleOptions.push(option);
		}
		if (this.config.sort) visibleOptions.sort((a, b) => {
			if (a.normalizedLabel === b.normalizedLabel) return 0;
			return a.normalizedLabel > b.normalizedLabel ? 1 : -1;
		});
		this.visibleOptions = visibleOptions;

		this.selectOption(
			(this.config.selectedOption && this.options.find((option) => option.label === this.config.selectedOption)) ||
			this.options.find((option) => option.isDefault) ||
			this.options[0]
		);
		if (this.config.isSearchable) this.search = new DropdownSearch(this);
		if (this.config.onCreate) this.config.onCreate(this);

		this.focusListener = (event) => {
			const target = event.target as HTMLElement;
			if (!target) return this.close();
			if (target === this.element || target.parentElement === this.element || target.parentElement === this.innerElement) return;
			if (target instanceof HTMLAnchorElement && this.options.map((option) => option.element).includes(target)) return;
			setTimeout(() => this.close(), 0);
		};
	}

	public selectOptionData(data: any): void {
		const selectedOption = this.options.find((option) => option.data === data);
		if (selectedOption) selectedOption.select();
	}

	public selectOption(selectedOption: DropdownOption): boolean {
		if (selectedOption.isDisabled) return false;
		if (selectedOption.onSelect) selectedOption.onSelect();
		if (!this.config.isSelectable && this.selectedOption) return true;

		const previousOption = this.selectedOption;
		this.selectedOption = selectedOption;
		for (const option of this.options) option.isSelected = option === selectedOption;
		if (this.onChange && previousOption !== selectedOption) this.onChange(this.selectedOption);
	}
	public setFocus(option: DropdownOption): void {
		if (this.focusedOption) this.focusedOption.element.blur();
		if (option.element) option.element.focus();
		this.focusedOption = option;
	}
	public clearFocus(): void {
		if (this.focusedOption) this.focusedOption.element.blur();
		delete this.focusedOption;
	}
	public scrollTo(position: DropdownOption|number, center?: boolean): void {
		if (typeof position === 'number') {
			this.listElement.scrollTop = position;
		} else if (position.element) {
			this.listElement.scrollTop = position.element.offsetTop - (center ? position.element.offsetHeight * 2 : 0);
		}
	}

	private onClick(event: MouseEvent): boolean {
		if (!this.justFocused) this[this.isOpen ? 'close' : 'open']();
		this.justFocused = false;
		return true;
	}
	private onFocus(event: FocusEvent): void {
		if (this.isOpen) return;
		this.justFocused = true;
		this.open();
	}
	private onKey(event: KeyboardEvent): boolean {
		switch (event.key) {
			case 'ArrowUp':
				this.focusIndex(-1);
				break;

			case 'ArrowDown':
				this.focusIndex(1);
				break;

			case 'Enter':
			case 'Escape':
				this.close();
			default:
				return true;
		}
	}
	private onTransitionEnd(): void {
		this.element.classList.remove('animating');
	}

	private focusIndex(increment: 1|-1) {
		if (!this.isOpen) this.open();
		let option: DropdownOption;
		let pos = this.visibleOptions.indexOf(this.focusedOption || this.selectedOption);
		if (pos < 0 && increment < 0) pos = 0;
		const origPos = pos;
		while (!option || option.isDisabled) {
			if (increment > 0) {
				pos = pos >= this.visibleOptions.length - 1 ? 0 : (pos + 1);
			} else {
				pos = (pos || this.visibleOptions.length) - 1;
			}
			option = this.visibleOptions[pos];
			if (pos === origPos) break;
		}
		this.setFocus(option);
	}
	private open(): void {
		if (!this.isOpen) this.element.classList.add('animating', 'open');
		this.isOpen = true;
		this.innerElement.style.maxHeight = `${this.listElement.offsetHeight + (this.search ? this.search.element.offsetHeight : 0) + 10}px`;
		this.clearFocus();
		for (const type of ['focusin', 'click']) document.addEventListener(type, this.focusListener);
		if (this.search && this.search.element) this.search.element.focus();
		this.element.scrollTop = 0;
		if (this.selectedOption) this.scrollTo(this.selectedOption, true);
	}
	private close(): void {
		if (this.isOpen) this.element.classList.add('animating');
		this.element.classList.remove('open');
		this.isOpen = false;
		this.innerElement.style.maxHeight = '';
		if (this.search) this.search.clear();
		for (const type of ['focusin', 'click']) document.removeEventListener(type, this.focusListener);
	}
}
