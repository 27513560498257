import {DateField} from "form";
import {bindable, observable} from "aurelia-framework";
import {Time} from "./time";
import {Month} from "./month";
import {DateFormatter} from "dateFormatter";

export class DatePicker {
	@bindable private field: DateField;
	@observable public date?: Date;
	public now?: Date;
	private originalDate?: Date;
	private readonly weekdays: string[] = DateFormatter.days.map((day) => day.substring(0, 2));
	private month: Month;
	private displayValue: string;
	private time: Time;
	private isOpen: boolean = false;

	constructor() {}

	private attached(): void {
		this.field.datePicker = this;
		this.value = this.field.originalValue;
	}

	private open(): void {
		if (this.isOpen) return;
		this.originalDate = this.date && new Date(this.date);
		if (!this.date) {
			this.date = new Date();
			this.date.setHours(0, 0, 0, 0);
		}
		this.now = new Date();
		this.month = new Month(this, this.date.getFullYear(), this.date.getMonth());
		this.time = new Time(this);
		this.isOpen = true;
		this.field.containerElement.classList.add('datepicker-open');
	}

	private close(): void {
		this.isOpen = false;
		this.field.containerElement.classList.remove('datepicker-open');
	}

	private clear(): void {
		this.date = null;
		this.close();
	}

	private cancel(): void {
		this.date = this.originalDate;
		this.close();
	}

	private changeMonth(increment: -1|1): void {
		let {month, year} = this.month;
		this.month = new Month(this, year, month + increment);
	}

	public dateChanged(): void {
		this.displayValue = this.date ? DateFormatter.format(this.date) : null;
		if (this.month) this.month.refresh();
		if (this.field && this.field.onChange) this.field.onChange();
	}

	public get value(): string {
		return this.date && this.date.toISOString();
	}

	public set value(value: string) {
		this.date = value && new Date(value) || null;
		this.close();
	}
}
