import {autoinject, viewResources, PLATFORM, useView} from 'aurelia-framework';
import {WebService, WebServiceResponse} from 'webservice';
import {App} from 'app';
import {Route} from 'route';
import {ItemEditorField, ItemEditorInputField, ItemEditorStaticField, ItemEditorStaticTable} from './field';
import {UploadData} from 'elements/upload';
import {DialogButton} from 'elements/dialog';

export class ItemEditorSectionButton {
	private isLink: boolean;

	constructor(
		private readonly icon: string,
		public label: string,
		private readonly action: ((event: MouseEvent) => void)|string,
		private readonly target: string = '_blank'
	) {
		this.isLink = typeof this.action === 'string';
	}

	private click(event: MouseEvent): void {
		(event.target as HTMLElement).blur();
		(this.action as Function)(event);
	}
}

export class ItemEditorSection {
	public container: ItemEditorContainer;
	public isHidden: boolean = false;
	public isLoading: boolean = false;

	constructor(
		public title: string,
		public readonly fields: ItemEditorField[],
		public cssClass?: string,
		public buttons?: ItemEditorSectionButton[]
	) {}

	public set button(button: ItemEditorSectionButton) {
		this.buttons = [button];
	}
}

export class ItemEditorContainer {
	public readonly fields: ItemEditorField[] = [];
	public isHidden: boolean = false;

	constructor(
		public readonly sections: ItemEditorSection[],
		public cssClass?: string
	) {
		for (const section of sections) {
			section.container = this;
			this.fields.push(...section.fields);
		}
	}
}

export class ItemEditorColumn {
	public readonly containers: ItemEditorContainer[] = [];
	public readonly sections: ItemEditorSection[] = [];

	constructor(
		containers: (ItemEditorContainer|ItemEditorSection)[],
		public cssClass?: string
	) {
		for (const container of containers) {
			if (container instanceof ItemEditorContainer) {
				this.containers.push(container);
				this.sections.push(...container.sections);
			} else {
				this.containers.push(new ItemEditorContainer([container]));
				this.sections.push(container);
			}
		}
	}
}

export class ItemCloneConfigParams {
	label?: string;
	icon?: string;
	onClone?: () => void;
	onSave?: (saveData: {[index: string]: any}) => boolean;
}

export class ItemCloneConfig implements ItemCloneConfigParams {
	public readonly label: string = 'Clone';
	public readonly icon: string = 'clone';
	onClone?: ItemCloneConfigParams['onClone'];
	onSave?: ItemCloneConfigParams['onSave'];

	constructor(params?: ItemCloneConfigParams) {
		Object.assign(this, params);
	}
}

export interface ItemEditorSaveResponse extends WebServiceResponse {
	updatedAt: string,
	image?: string
}

@autoinject
@viewResources(PLATFORM.moduleName('elements/field'))
@useView(PLATFORM.moduleName('elements/itemeditor/index.html'))
export abstract class ItemEditor {
	public abstract readonly webService: string;
	protected abstract readonly responseParam: string;
	protected abstract readonly columns: ItemEditorColumn[];

	protected readonly sections: ItemEditorSection[] = [];
	protected readonly fields: ItemEditorField[] = [];

	protected readonly allowNew: boolean = true;
	protected readonly allowSave: boolean = true;
	protected readonly defaults: {}|(() => Promise<{}>) = () => Promise.resolve({[this.responseParam]: {}});
	protected readonly cloneConfig?: ItemCloneConfig;
	protected readonly cssClass: string = '';

	private isLoading: boolean = true;
	private isBusy: boolean = true;
	private isDirty: boolean = false;
	private isNew: boolean = false;
	private isClone: boolean = false;
	private isLoaded: boolean = false;
	private status: string = 'Loading';
	private title: string;

	protected itemType: string;

	public itemId: string|null;
	public originalId: string|null;
	public route: Route;
	public error: string = '';

	private warnDirty: (event: BeforeUnloadEvent) => void;

	constructor(public readonly app: App) {
		this.warnDirty = (event) => {
			if (!this.isDirty) return;
			const message = 'Unsaved changes';
			if (event) event.returnValue = message;
			return message;
		};
	}

	public getSection(title: string): ItemEditorSection {
		return this.sections.find((section) => section.title === title);
	}
	public getField(path: string): ItemEditorField {
		return this.fields.find((field) => field.path === path);
	}

	private attached(): void {
		const {currentInstruction} = this.app.router;
		this.route = currentInstruction.config.settings.route;
		this.originalId = currentInstruction.params.id;
		this.isClone = currentInstruction.params.subroute === 'clone';
		this.isNew = this.originalId === 'new';
		if (!this.itemType) this.itemType = this.route.title;

		if (this.isClone) {
			this.itemId = null;
			this.title = 'Clone ' + this.itemType;
		} else if (this.isNew) {
			this.itemId = null;
			this.originalId = null;
			this.title = 'New ' + this.itemType;
		} else {
			this.itemId = this.originalId;
			this.title = (this.allowSave ? 'Edit ' : 'View ') + this.itemType;
		}

		if (this.isNew && !this.allowNew) {
			this.isLoading = false;
			this.isBusy = false;
			this.error = `Cannot create a new ${this.itemType} via admin panel`;
			return;
		}
		if (this.isLoaded) return;
		this.load();
		window.addEventListener('beforeunload', this.warnDirty);
	}

	private detached(): void {
		window.removeEventListener('beforeunload', this.warnDirty);
	}

	private canDeactivate(): Promise<boolean> {
		return new Promise((resolve) => {
			if (!this.checkDirty()) return resolve(true);
			this.app.dialogContainer.open(PLATFORM.moduleName('views/dialogs/unsaved-changes.html'), {itemType: this.itemType}, {
				heading: 'Unsaved Changes',
				buttons: [
					new DialogButton('check-circle', 'Continue', (dialog) => {
						dialog.closeAll();
						resolve(true);
					}),
					new DialogButton('ban', 'Keep editing', (dialog) => {
						dialog.closeAll();
						resolve(false);
					})
				],
				hideCloseButton: true
			});
		});
	}

	public checkDirty(): boolean {
		this.isDirty = false;
		for (const field of this.fields) {
			if (field.checkDirty()) this.isDirty = true;
		}
		return this.isDirty;
	}

	private load(): void {
		this.isLoading = true;
		this.isBusy = true;
		this.status = 'Loading';
		for (const column of this.columns) this.sections.push(...column.sections);
		for (const section of this.sections) this.fields.push(...section.fields);
		const load = (): Promise<WebServiceResponse> => {
			if (this.isNew) return typeof this.defaults === 'function' ? this.defaults() : Promise.resolve(this.defaults);
			return WebService.post(`${this.webService}/details`, {id: this.originalId});
		};
		load().then((response) => {
			this.loaded(response);
		});
	}

	protected loaded(response: WebServiceResponse): void {
		for (const field of this.fields) field.populate(this, response[this.responseParam]);
		if (this.fields[0] instanceof ItemEditorInputField) (this.fields[0] as ItemEditorInputField).field.hasAutoFocus = true;
		this.isLoading = false;
		this.isBusy = false;
		this.isLoaded = true;
		this.checkDirty();
	}

	private save(element: HTMLButtonElement): void {
		if (this.isBusy || !this.checkDirty()) return;
		element.blur();
		this.isBusy = true;
		this.status = 'Saving';
		this.error = '';
		let isValid = true;

		const saveData: {[index: string]: any} = {_id: this.itemId || 'new'};
		for (const field of this.fields) {
			if (field instanceof ItemEditorStaticField) continue;
			if (field instanceof ItemEditorStaticTable && !field.field.config.isSavable) continue;
			const save = this.isNew || this.isClone || field.checkDirty();
			if (save) field.save(saveData);
			if ((save || field.field.isRequired) && !field.field.validate()) isValid = false;
		}
		if (this.isClone && !saveData.upload) {
			saveData.upload = new UploadData(this.originalId, 'jpeg', true);
		}

		if (isValid && this.isClone && this.cloneConfig && this.cloneConfig.onSave) isValid = this.cloneConfig.onSave(saveData);

		if (!isValid) return this.saved(saveData, 'Save failed. Please correct the errors highlighted in red below.');

		WebService.post(`${this.webService}/save`, saveData).then((response: ItemEditorSaveResponse) => {
			for (const field of this.fields) if (field.saved) field.saved(response);
			if (this.isNew || this.isClone) {
				this.app.router.navigateToRoute(this.route.name, {id: response._id}).then(() => {
					this.attached();
				});
			}
			this.checkDirty();
			this.saved(saveData, null, response);
			if (window.opener) window.opener.dispatchEvent(new CustomEvent('sbItemChanged', {detail: this.route.baseRoute}));
		}, (e) => {
			this.saved(saveData, e);
		});
	}

	protected saved(saveData: {[index: string]: any}, error?: string, response?: ItemEditorSaveResponse): void {
		this.isBusy = false;
		if (error) this.error = error;
	}

	private close(): void {
		this.canDeactivate().then((canDeactivate) => {
			if (!canDeactivate) return;
			this.detached();
			if (window.opener) {
				window.close();
			} else {
				location.href = '/' + this.route.baseRoute;
			}
		});
	}

	private clone(element: HTMLButtonElement): void {
		if (this.isBusy || this.isClone || this.isDirty || !this.cloneConfig) return;
		element.blur();
		this.app.router.navigateToRoute(this.route.name, {id: this.itemId, subroute: 'clone'}).then((result) => {
			if (!result) return;
			if (this.cloneConfig.onClone) this.cloneConfig.onClone();
			this.attached();
		});
	}

	private cancelClone(): void {
		if (this.isBusy) return;
		for (const field of this.fields) field.reset();
		this.checkDirty();
		this.app.router.navigateToRoute(this.route.name, {id: this.originalId}).then(() => {
			this.attached();
		});
	}
}
