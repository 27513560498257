import {bindable, PLATFORM, viewResources} from 'aurelia-framework';
import {DialogContainer} from 'elements/dialog/container';
import {WebService} from 'webservice';
import {AddressData} from 'elements/address';
import {Dialog} from 'elements/dialog';

interface RolodexConfig {
	customerId: string;
	selectedAddress: AddressData;
	onSelect: (address: AddressData) => void;
}


@viewResources(PLATFORM.moduleName('elements/address'))
export class Rolodex {
	@bindable private readonly config: RolodexConfig;
	@bindable private readonly dialog: Dialog;
	private error: string;
	private isLoading: boolean = true;
	private addresses: AddressData[];
	private defaultAddress: AddressData;
	private selectedAddress: AddressData;

	private attached(): void {
		if (!this.config.customerId) {
			this.addresses = [];
			if (this.config.selectedAddress) {
				this.selectedAddress = this.defaultAddress = Object.assign({isSelected: true, isDefault: true}, this.config.selectedAddress);
				this.addresses.push(this.selectedAddress);
			}
			this.isLoading = false;
			return;
		}
		WebService.post('customers/addresses', {id: this.config.customerId}).then((response) => {
			this.addresses = response.addresses;
			this.defaultAddress = this.addresses && this.addresses.find((address) => address._id === response.defaultAddress);
			this.selectedAddress = this.config.selectedAddress && this.addresses && this.addresses.find((address) => address._id === this.config.selectedAddress._id);
			if (this.defaultAddress) this.defaultAddress.isDefault = true;
			if (this.selectedAddress) this.selectedAddress.isSelected = true;
		}, (error) => {
			this.error = error;
		}).finally(() => {
			this.isLoading = false;
		});
	}

	private cssClass(address: AddressData): string {
		const classes: string[] = [];
		if (address.isDefault) classes.push('default');
		if (address.isSelected) classes.push('selected');
		return classes.join(' ');
	}

	private select(address: AddressData): void {
		if ((this.config.customerId || !address) && this.config.onSelect) this.config.onSelect(address);
		if (this.dialog) this.dialog.close();
	}

	public static openDialog(dialogContainer: DialogContainer, config: RolodexConfig): void {
		dialogContainer.open(PLATFORM.moduleName('elements/rolodex/dialog.html'), {config}, {
			heading: 'Address Book',
			buttons: []
		});
	}
}
