export class Progress {
	public percent: number = 0;
	private _total: number = 0;
	private _completed: number = 0;
	private cssClass: string;

	private attached(): void {
		this.reset();
	}

	private calc(): void {
		this.percent = Math.floor(this.completed / this.total * 100);
		this.cssClass = this.percent === 100 ? 'complete' : '';
	}

	public reset(): void {
		this.percent = 0;
		this._total = 0;
		this._completed = 0;
		this.cssClass = 'disabled';
	}

	public set total(total: number) {
		this._total = total;
		this.calc();
	}
	public get total(): number {
		return this._total;
	}

	public set completed(completed: number) {
		this._completed = completed;
		this.calc();
	}
	public get completed(): number {
		return this._completed;
	}

}
