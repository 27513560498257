import {bindable, observable} from "aurelia-framework";
import {WebService} from "webservice";
import {UploadField} from "form";
import {ItemEditorSaveResponse} from "elements/itemeditor";

export interface UploadConfig {
	id?: string;
	webService?: string;
	params?: any;
	thumbSize?: number;
	updatedAt?: Date;
	onChange?(): void;
	onCreate?(upload: Upload): void;
}

export class UploadData {
	constructor (
		public readonly id: string,
		public readonly ext: string,
		public readonly isClone: boolean = false,
		public readonly append?: string
	) {}
}

export class Upload {
	@bindable private config: UploadConfig;
	@observable private currentImage: string;
	private target: HTMLDivElement;
	private originalImage: string;
	private imageUri: string;
	private isTargeted: boolean = false;
	private isUploading: boolean = false;
	private isProcessing: boolean = false;
	private progress: number = 0;
	private updatedAt?: Date;
	public field: UploadField;
	public value?: UploadData;

	private attached(): void {
		if (this.config.onCreate) this.config.onCreate(this);
	}

	private onDragover(event): void {
		if (event.dataTransfer.items[0].kind !== 'file' || this.isUploading) return;
		event.dataTransfer.effectAllowed = 'move';
		event.dataTransfer.dropEffect = 'move';
		event.stopPropagation();
		event.preventDefault();
	}

	private onDragenter(event): void {
		if (event.dataTransfer.items[0].kind !== 'file') return;
		this.isTargeted = true;
	}

	private onDragleave(event): void {
		if (event.dataTransfer.items[0].kind !== 'file') return;
		this.isTargeted = false;
	}

	private onDrop(event): void {
		if (event.dataTransfer.items[0].kind !== 'file') return;
		this.isTargeted = false;
		this.isUploading = true;
		this.isProcessing = false;
		this.field.flag();
		event.stopPropagation();
		event.preventDefault();

		const data = new FormData();
		data.append('params', JSON.stringify({id: this.config.id, opts: this.config.params}));
		data.append('file', event.dataTransfer.files[0]);

		WebService.upload(this.config.webService, data, ({lengthComputable, loaded, total}: ProgressEvent) => {
			if (lengthComputable) {
				this.progress = Math.round(loaded / total * 100);
				if (this.progress >= 100) {
					this.isUploading = false;
					this.isProcessing = true;
				}
			}
		}).then(({id, ext, path}) => {
			this.value = new UploadData(id, ext);
			this.updatedAt = new Date;
			this.image = `${path}new_${id}`;
		}, (error) => {
			this.field.flag(error);
		}).then(() => {
			this.isUploading = false;
			this.isProcessing = false;
		});
	}

	private currentImageChanged(): void {
		if (this.config.onChange) this.config.onChange();
	}

	public reset(): void {
		delete this.updatedAt;
		delete this.value;
		this.image = this.originalImage;
	}

	public saved({updatedAt, image}: ItemEditorSaveResponse): void {
		if (!this.value) return;
		delete this.value;
		if (updatedAt) this.updatedAt = new Date(updatedAt);
		this.image = image;
		this.originalImage = this.image;
	}

	public get image(): string {
		return this.currentImage;
	}

	public set image(image: string) {
		if (!this.originalImage) this.originalImage = image;
		this.currentImage = image;
		this.imageUri = this.currentImage && `${this.currentImage}_${this.config.thumbSize}.jpeg?${(this.updatedAt || this.config.updatedAt).getTime()}`
	}
}
