import {ItemTable} from '.';
import {Dropdown, DropdownOption, DropdownConfig, DropdownSeparator} from '../dropdown';

export class FilterOption extends DropdownOption {
	constructor(label: string, public query: any, isDefault: boolean = false) {
		super(label, {isDefault: isDefault});
	}
}

export class FilterConfig {
	constructor(public readonly title: string, public readonly options: (FilterOption|DropdownSeparator)[]) {}

	public static get statusFilter(): FilterConfig {
		return new FilterConfig('Status', [
			new FilterOption('All', {}),
			new FilterOption('Active', {isDisabled: {$ne: true}}),
			new FilterOption('Disabled', {isDisabled: true})
		]);
	}
}

export class Filter extends FilterConfig {
	public dropdownConfig: DropdownConfig;
	public dropdown: Dropdown;
	public selectedOption: FilterOption;
	public readonly load: Promise<void>;

	constructor(public readonly table: ItemTable, public readonly config: FilterConfig, appliedFilters: any) {
		super(config.title, config.options);

		this.load = new Promise((resolve) => {
			this.dropdownConfig = new DropdownConfig(this.options, {
				selectedOption: appliedFilters && appliedFilters[this.title],
				onChange: (selectedOption: FilterOption) => {
					const filter = this.table.tableParams.filter || {};
					this.selectedOption = selectedOption;
					if (this.table.updateParams({filter: Object.assign(filter, {[this.title]: selectedOption.label})})) this.table.load();
				},
				onCreate: (dropdown: Dropdown) => {
					this.dropdown = dropdown;
					resolve();
				}
			});
		});
	}
}
