import {bindable, observable} from "aurelia-framework";
import {Chart} from "../../chart";

// The circle will be this many times the size of the slice ring
// 1 = circle completely filled
// This must also be kept in sync in pieChart.less
const pieRadius: number = 2.5;

const sliceRadius: number = pieRadius - 0.5;

class Slice {
	public circumference: number = Math.PI * sliceRadius * 2;
	public offset: number;
	public size: number;
	public style: {'stroke-dashoffset': string, 'stroke-dasharray': string};

	constructor(public startRatio: number, public sizeRatio: number) {
		this.offset = (this.circumference / 4) - (this.startRatio * this.circumference);
		this.size = this.sizeRatio * this.circumference;
		this.style = {
			'stroke-dasharray': `${this.size} ${this.circumference - this.size}`,
			'stroke-dashoffset': `${this.offset}`
		}
	}
	public get endRatio(): number {
		return this.sizeRatio + this.startRatio;
	}
}

export class Piechart {
	public readonly pieRadius: number = pieRadius;
	public readonly sliceRadius: number = sliceRadius;

	@bindable @observable public chart: Chart;
	public slices: Slice[];

	chartChanged(chart: Chart): void {
		let previousEnd = 0;
		this.slices = chart.segments.map((segment) => {
			const slice = new Slice(previousEnd, segment.ratio);
			previousEnd = slice.endRatio;
			return slice;
		});
	}
}
