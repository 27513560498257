import {getProperty, copyValue} from "utils";
import {Field, BooleanField, DropdownField, TextAreaField, PriceField, UploadField, CustomField, InputField, RichTextField, ListEditorField, DateField, NumericInputField, StaticField, TelInputField, EmailInputField, StaticItemTableField, StaticListItem, StaticAddressField, AddressField} from "form";
import {ItemEditor, ItemEditorSaveResponse} from ".";
import {DropdownOption} from "elements/dropdown";
import {StaticItemTableConfig} from "elements/itemtable/static";
import {DateFormatter} from "dateFormatter";
import {ListEditorConfig} from "elements/listeditor";
import {Price} from "price";
import {Rolodex} from 'elements/rolodex';
import {App} from 'app';
import {AddressData} from 'elements/address';

export abstract class ItemEditorField {
	public abstract field: Field;
	public originalValue: any;
	public isDirty: boolean = false;
	public isHidden: boolean = false;
	protected readonly isEditable: boolean = true;
	protected readonly isCopyable: boolean = false;
	protected readonly hierarchy: string[];
	protected readonly property: string;
	protected editor: ItemEditor;

	protected abstract watchChanges(): void;
	public parseValue?(value: any, rawData: any): any;
	public onChange?(this: ItemEditorField): void;
	public onPopulate?(this: ItemEditorField): void;

	constructor(public readonly path: string) {
		this.hierarchy = this.path.split('.');
		this.property = this.hierarchy.pop();
	}

	protected setField(field: Field): void {
		this.field = field;
		this.field.isInEditor = true;
		this.field.htmlId = 'field-' + this.path.replace(/\./g, '-');
		if (this.isEditable) this.field.cssClass = ['editable', this.field.cssClass].join(' ');
		this.field.watchChanges = () => {
			this.watchChanges();
		};
	}

	private copyValue(): void {
		copyValue(this.stringValue);
	}

	public populate(editor: ItemEditor, rawData: any): void {
		this.editor = editor;
		const parent = this.hierarchy.length ? getProperty(rawData, this.hierarchy) : rawData;
		const value = parent && parent[this.property];
		this.originalValue = this.parseValue ? this.parseValue(value, rawData) : value;
		this.field.value = this.originalValue;
		if (this.onPopulate) this.onPopulate();
	}

	public save(saveData: any, value: any = this.field.value): void {
		if (!saveData) return;
		const parent = this.hierarchy.length ? this.hierarchy.reduce((obj, segment) => {
			if (!obj[segment]) obj[segment] = {};
			return obj[segment];
		}, saveData) : saveData;
		parent[this.property] = value;
	}

	public checkDirty(): boolean {
		return this.isDirty = this.originalValue !== this.field.value;
	}

	public reset(): void {
		if (this.field) {
			this.field.value = this.originalValue;
			this.field.flag();
		}
		this.editor.checkDirty();
	}

	public saved(response: ItemEditorSaveResponse): void {
		this.originalValue = this.field.value;
	}

	public get stringValue(): string {
		return this.field.value && this.field.value.toString();
	}
}

export class ItemEditorStaticField extends ItemEditorField {
	public readonly field: StaticField;
	protected readonly isEditable = false;
	protected isCopyable = true;
	private disableCopying = !this.isCopyable;
	protected originalParser?: (value: any, rawData: any) => string;

	constructor(
		path: string,
		label: string,
		public readonly linkRoute?: string|((rawData: any) => string),
		public readonly linkPath?: string,
		protected readonly defaultValue?: string,
		parseValue?: (value: any, rawData: any) => string,
		protected readonly linkTarget?: string
	) {
		super(path);
		if (parseValue) {
			this.originalParser = this.parseValue;
			this.parseValue = parseValue;
		}
		this.setField(new StaticField(this.property, label));
		if (linkTarget) this.field.linkTarget = linkTarget;
	}

	protected watchChanges(): void {
		// No action
	}

	public parseValue(value: any, rawData: any): string {
		this.disableCopying = !this.isCopyable || !value;
		return value || this.defaultValue;
	}

	public addStaticLink(link: string|(() => void)): void {
		this.field.link = link;
	}

	public createLink(value: any, rawData: any): void {
		this.field.link = this.getLink(value, rawData);
	}

	public getLink(value: any, rawData: any): string {
		if (this.linkRoute === undefined || !value) return;
		if (!this.linkRoute) return value;

		const linkRoute = typeof this.linkRoute === 'function' ? this.linkRoute(rawData) : this.linkRoute;
		return linkRoute.includes(':') ? `${linkRoute}${value}` : `/${linkRoute}/${value}`;
	}

	public populate(editor: ItemEditor, rawData: any): void {
		if (typeof this.linkRoute === 'string') this.createLink(getProperty(rawData, this.linkPath || this.path), rawData);
		super.populate(editor, rawData);
	}

	public checkDirty(): boolean {
		return this.isDirty = false;
	}
}

export class ItemEditorStaticBoolean extends ItemEditorStaticField {
	constructor(
		path: string,
		label: string,
		private readonly trueValue: string = 'Yes',
		private readonly falseValue: string = 'No'
	) {
		super(path, label);
	}

	public parseValue(value: any): string {
		this.field.cssClass = value ? 'boolean-true' : 'boolean-false';
		return value ? this.trueValue : this.falseValue;
	}
}

export class ItemEditorStaticList extends ItemEditorStaticField {
	protected isCopyable = false;

	constructor(
		path: string,
		label: string,
		private readonly valueProperty?: string,
		linkRoute?: string|((rawData: any) => string),
		linkPath?: string,
		defaultValue?: string,
		linkTarget?: string
	) {
		super(path, label, linkRoute, linkPath, defaultValue, undefined, linkTarget);
	}

	public populate(editor: ItemEditor, rawData: any): void {
		super.populate(editor, rawData);
		if (this.field.value && Array.isArray(this.field.value)) {
			const items = (this.field.value as any[]).map((rawValue) => {
				return this.valueProperty ? getProperty(rawValue, this.valueProperty) : rawValue;
			}).filter((value) => !!value).map((value) => {
				return new StaticListItem(value, this.getLink(value, rawData), this.linkTarget)
			});
			if (items.length) {
				this.field.items = items;
			} else {
				this.field.value = this.defaultValue;
			}
		}
	}
}

export class ItemEditorStaticCount extends ItemEditorStaticField {
	constructor(
		path: string,
		label: string,
		public readonly itemAccumulatorProperty: string = 'quantity'
	) {
		super(path, label);
	}

	public parseValue(value: any): string {
		return value && value.reduce((total: number, item: any): number => {
			return total + (getProperty(item, this.itemAccumulatorProperty) || 0);
		}, 0).toString() || '0';
	}
}

export class ItemEditorStaticPrice extends ItemEditorStaticField {
	public parseValue(value: number): string {
		return value ? '$' + Price.fromCents(value).toString() : '-';
	}
}

export class ItemEditorStaticDate extends ItemEditorStaticField {
	constructor(
		path: string,
		label: string,
		defaultValue: string = 'No',
		private readonly dateFormatter: DateFormatter = new DateFormatter({fullNames: true})
	) {
		super(path, label, undefined, undefined, defaultValue);
	}

	public parseValue(value: string, rawData: any): string {
		return super.parseValue(value && this.dateFormatter.format(new Date(value)), rawData);
	}
}

export class ItemEditorStaticAddress extends ItemEditorField {
	public readonly field: StaticAddressField;
	protected readonly isEditable = false;
	protected isCopyable = true;

	constructor(
		path: string,
		label: string,
		protected readonly defaultValue: string = 'None'
	) {
		super(path);
		this.setField(new StaticAddressField(this.property, label));
	}

	protected watchChanges(): void {
		// No action
	}

	public parseValue(value: any): string {
		if (this.isCopyable && !value) this.isCopyable = false;
		return value || this.defaultValue;
	}

	public checkDirty(): boolean {
		return this.isDirty = false;
	}

	public get stringValue(): string {
		return this.field && this.field.address && this.field.address.stringValue;
	}
}

export abstract class ItemEditorCustomField extends ItemEditorField {
	public abstract field: CustomField;
	
	protected watchChanges(): void {
		this.field.onChange = () => {
			if (this.onChange) this.onChange();
			this.field.flag();
			this.editor.checkDirty();
		}
	}
}

export class ItemEditorStaticTable extends ItemEditorCustomField {
	public readonly isItemTable: true = true;
	public readonly field: StaticItemTableField;

	constructor(path: string, config: StaticItemTableConfig) {
		super(path);
		this.setField(new StaticItemTableField(this.property, config));
	}

	public save(saveData: any): void {
		super.save(saveData, this.field.value && this.field.value.map(({productId}) => productId));
	}

	protected watchChanges(): void {
		if (!this.field.config.isSavable) return;
		super.watchChanges();
	}

	public checkDirty(): boolean {
		if (!this.field.config.isSavable) return this.isDirty = false;
		return this.isDirty = this.field.value !== this.originalValue;
	}
}

export class ItemEditorInputField extends ItemEditorField {
	public readonly field: TextAreaField|InputField|NumericInputField|TelInputField|EmailInputField;
	protected readonly isCopyable = true;

	constructor(path: string, label: string, isRequired: boolean = false, ThisInputField: typeof TextAreaField|typeof InputField|typeof NumericInputField = TextAreaField, placeholder?: string) {
		super(path);
		this.setField(new ThisInputField(this.property, label, isRequired));
		this.field.hasPlaceholder = placeholder || true;
	}

	public watchChanges(): void {
		this.field.element.addEventListener('input', () => {
			if (this.onChange) this.onChange();
			this.field.flag();
			this.editor.checkDirty();
		});
		this.field.element.addEventListener('focus', () => {
			setTimeout(() => {
				(this.field.element as HTMLInputElement).select();
			}, 0);
		});
	}
}

export class ItemEditorRichTextField extends ItemEditorCustomField {
	public readonly field: RichTextField;

	constructor(path: string, label: string, isRequired: boolean = false, placeholder?: string) {
		super(path);
		this.setField(new RichTextField(this.property, label, isRequired));
		this.field.hasPlaceholder = placeholder || true;
	}

	public saved(response: ItemEditorSaveResponse): void {
		super.saved(response);
		this.field.saved(response);
	}

	public checkDirty(): boolean {
		return this.isDirty = (!this.field || !this.field.richText ? false : this.field.richText.isDirty);
	}

	public get originalValue(): string {
		return this.field.originalValue;
	}
	public set originalValue(value: string) {
		this.field.originalValue = value;
	}
}

export class ItemEditorDateField extends ItemEditorCustomField {
	public readonly field: DateField;

	constructor(path: string, label: string, isRequired: boolean = false, placeholder?: string) {
		super(path);
		this.setField(new DateField(this.property, label, isRequired));
		this.field.hasPlaceholder = placeholder || true;
	}

	public get originalValue(): string {
		return this.field.originalValue;
	}
	public set originalValue(value: string) {
		this.field.originalValue = value || null;
	}
}

export class ItemEditorPriceField extends ItemEditorField {
	public readonly field: PriceField;
	protected watchChanges: () => void;

	constructor(path: string, label: string, isRequired: boolean = false) {
		super(path);
		this.setField(new PriceField(this.property, label, isRequired));
		this.field.hasPlaceholder = true;
		this.watchChanges = ItemEditorInputField.prototype.watchChanges;
	}
}

export class ItemEditorBooleanField extends ItemEditorCustomField {
	public readonly field: BooleanField;

	constructor(path: string, label: string, defaultValue?: boolean, invert?: boolean) {
		super(path);
		this.setField(new BooleanField(this.property, label, defaultValue, invert));
	}
}

export class ItemEditorDropdownOption {
	constructor(public readonly value: string, public readonly label?: string) {
		if (!this.label) this.label = this.value;
	}
}

export class ItemEditorDropdownField extends ItemEditorCustomField {
	public readonly field: DropdownField;

	constructor(
		path: string,
		private label: string,
		options?: (ItemEditorDropdownOption|string)[],
		private isRequired: boolean = false,
		private hasEmptyDefault: boolean = false,
		private isSearchable?: boolean
	) {
		super(path);
		if (options) this.setOptions(options);
	}
	public setOptions(options?: (ItemEditorDropdownOption|string)[]): void {
		const dropdownOptions = options.map((option) => {
			if (!(option instanceof ItemEditorDropdownOption)) option = new ItemEditorDropdownOption(option);
			return new DropdownOption(option.label, {data: option.value});
		});
		if (this.hasEmptyDefault) dropdownOptions.unshift(new DropdownOption('Select', {isDefault: true, isHidden: true}));
		this.setField(new DropdownField(this.property, this.label, dropdownOptions, this.isRequired, this.isSearchable));
	}
}

export class ItemEditorListEditorField extends ItemEditorCustomField {
	public readonly field: ListEditorField;
	protected readonly isCopyable = true;

	constructor(path: string, label: string, webService: string, config: ListEditorConfig) {
		super(path);
		this.setField(new ListEditorField(this.property, label, webService, config));
	}

	public checkDirty(): boolean {
		return this.isDirty = (!this.field || !this.field.listEditor ? false : this.field.listEditor.isDirty);
	}

	public saved(response: ItemEditorSaveResponse): void {
		super.saved(response);
		this.field.saved(response);
	}

	public get stringValue(): string {
		return this.field.listEditor.selectedItems.map((item) => item.label).join('\r\n');
	}
}

export class ItemEditorUploadField extends ItemEditorCustomField {
	public readonly field: UploadField;
	private readonly imageProperty: string;

	constructor(path: string, label: string, params?: any, thumbSize?: number) {
		const segments = path.split('.');
		const imageProperty = segments.pop();
		segments.push('upload');
		super(segments.join('.'));
		this.imageProperty = imageProperty;
		this.setField(new UploadField(this.property, label, params, thumbSize));
	}

	public populate(editor: ItemEditor, rawData: any): void {
		this.editor = editor;
		this.field.updateConfig({id: editor.itemId, webService: editor.webService, updatedAt: new Date(rawData.updatedAt)});

		const parent = this.hierarchy.length ? getProperty(rawData, this.hierarchy) : rawData;
		if (parent) this.field.image = parent[this.imageProperty];
		if (this.onPopulate) this.onPopulate();
	}

	public reset(): void {
		if (this.field) this.field.reset();
		this.editor.checkDirty();
	}

	public saved(response: ItemEditorSaveResponse): void {
		this.field.saved(response);
	}
}

export class ItemEditorAddressField extends ItemEditorCustomField {
	public readonly field: AddressField;
	protected readonly isCopyable = true;

	constructor(idPath: string, private readonly collection: AddressData[]|string, label: string, getCustomerId: () => string, app: App) {
		super(idPath);
		this.setField(new AddressField('address', this.property, label, null, 'In-Store Pickup', () => {
			Rolodex.openDialog(app.dialogContainer, {
				customerId: getCustomerId(),
				selectedAddress: this.field.value,
				onSelect: (address) => {
					this.field.value = address;
				}
			});
		}));
	}

	public parseValue(value: string, rawData: any): AddressData {
		if (!value) return null;
		let collection: AddressData[];
		if (typeof this.collection === 'string') {
			const collectionHierarchy = this.collection.split('.');
			const collectionProperty = collectionHierarchy.pop();
			const parent = collectionHierarchy.length ? getProperty(rawData, collectionHierarchy) : rawData;
			collection = parent && parent[collectionProperty];
		} else {
			collection = this.collection;
		}
		return collection.find((address) => address._id === value);
	}

	public save(saveData: any): void {
		super.save(saveData, this.field.value && this.field.value._id);
	}

	public populate(editor: ItemEditor, rawData: any): void {
		super.populate(editor, rawData);
		this.onChange();
	}

	public onChange(): void {
		this.field.displayValue = this.field.value || this.field.defaultDisplayValue;
	}

	public checkDirty(): boolean {
		return this.isDirty = (this.originalValue && this.originalValue._id) !== (this.field.value && this.field.value._id);
	}

	public get stringValue(): string {
		return this.field.value ? this.field.address.stringValue : this.field.defaultDisplayValue as string;
	}
}
