import {DialogConfig} from '.';
import {viewResources, PLATFORM} from 'aurelia-framework';

@viewResources(PLATFORM.moduleName('elements/dialog'))
export class DialogContainer {
	private isActive: boolean = false;
	private dialog?: DialogConfig;
	private stack: DialogConfig[] = [];

	public open(
		bodyView: string,
		bodyModel: {[index: string]: any},
		config: DialogConfig
	): void {
		if (this.dialog) this.stack.push(this.dialog);
		this.isActive = true;
		this.dialog = Object.assign({bodyView, bodyModel}, config);
	}

	public close(closeAll?: boolean): void {
		if (this.stack.length && !closeAll) {
			this.dialog = this.stack.pop();
		} else {
			this.isActive = false;
			this.dialog = null;
			this.stack = [];
		}
	}
}
