import {ItemTable, ItemTableConfig} from 'elements/itemtable';
import {bindable} from 'aurelia-framework';
import {ListEditorField} from 'form';
import {Item} from 'elements/itemtable/item';
import {ListEditor} from '.';

export class ListEditorItemTable extends ItemTable {
	@bindable field: ListEditorField;
	public webService: string;
	public config: ItemTableConfig;
	protected readonly showTitle = false;
	protected readonly showNewButton = false;
	protected readonly showStatus = false;
	protected readonly showCount = false;
	protected readonly listAction: string = 'listAll';
	private listEditor: ListEditor;

	protected attached(): void {
		this.webService = this.field.webService;
		this.config = this.field.config.tableConfig;
		this.listEditor = this.field.listEditor;
		this.listEditor.availableItems = this;
		super.attached();
	}

	public load(): Promise<void> {
		return super.load().then(() => {
			this.listEditor.load();
		});
	}

	public onItemClick(item: Item): void {
		this.listEditor.addItem(item);
	}
}
