import {padNumber} from "utils";

interface DateFormat {
	longFormat?: boolean;
	withTime?: boolean;
	withDay?: boolean;
	fullNames?: boolean;
}

export class DateFormatter {
	static readonly days: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	static readonly months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	public longFormat: boolean = true;
	public withTime: boolean = true;
	public withDay: boolean = true;
	public fullNames: boolean = false;

	constructor(format?: DateFormat) {
		if (format) Object.assign(this, format);
	}

	public format(d: Date): string {
		const day = DateFormatter.days[d.getDay()];
		let month = DateFormatter.months[d.getMonth()];
		if (!this.fullNames) month = month.substring(0, 3);

		const hours = DateFormatter.convertTo12(d.getHours());
		const ampm = d.getHours() < 12 ? 'am' : 'pm';

		let ds = '';
		if (this.withDay) ds += `${this.fullNames ? day : day.substring(0, this.longFormat ? 3 : 2)} `;
		ds += this.longFormat ? `${month} ${d.getDate()} ${d.getFullYear()}` : `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
		if (this.withTime) ds += `, ${hours}:${padNumber(d.getMinutes())}${ampm}`

		return ds;
	}

	static format(date: Date, format?: DateFormat): string {
		return (new DateFormatter(format)).format(date);
	}

	static convertTo12(h: number): number {
		return h ? (h > 12 ? h - 12 : h) : 12;
	}

	static convertTo24(h: number, ampm: 'am'|'pm'): number {
		return ampm === 'am' ? (h === 12 ? 0 : h) : (h === 12 ? h : h + 12);
	}

	
}
