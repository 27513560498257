import {ItemTable, ItemTableConfig} from ".";
import {bindable} from "aurelia-framework";
import {Item} from "./item";
import {getProperty} from "utils";

export interface StaticItemTableConfig extends ItemTableConfig {
	readonly tableId: string;
	readonly createLinks?: boolean;
	readonly hideEmpty?: boolean;
	readonly idColumn?: string;
	readonly title?: string;
	readonly isSavable?: boolean;
	readonly defaultSort?: string;
	editButton?: () => void;
}

export class StaticItemTable extends ItemTable {
	@bindable public config: StaticItemTableConfig;
	@bindable public rawData: any;
	public readonly isStatic: boolean = true;

	protected titleTag = 'h3';
	protected showTitle = false;
	protected hideEmpty = false;
	public webService: string;
	public createLinks = false;
	public idColumn: string;

	protected readonly showNewButton = false;
	protected readonly showStatus = false;
	protected readonly showCount = true;
	protected readonly isLoading = false;
	private hasLoaded = false;
	public isBusy = false;

	protected attached(): void {
		this.webService = this.config.tableId;
		if (this.config.createLinks) this.createLinks = true;
		if (this.config.hideEmpty) this.hideEmpty = true;
		if (this.config.idColumn) this.idColumn = this.config.idColumn;
		if (this.config.title) {
			this.title = this.config.title;
			this.showTitle = true;
		}
		if (this.config.defaultSort) this.defaultSort = this.config.defaultSort;
		super.attached();
		if (this.search) this.search.autofocus = false;
	}

	public load(): Promise<void> {
		if (this.items) {
			for (const item of this.items) item[item.isHidden ? 'hiddenElement' : 'element'].remove();
			delete this.items;
		}
		this.items = this.rawData && this.rawData.map((item) => {
			return new Item(this, this.parseItem ? this.parseItem(item) : item, this.createLinkTest && this.createLinkTest(item));
		}).filter((item) => {
			return this.columns.find((column) => !!item.values[column.valueProperty].rawValue);
		}) || [];
		this.itemCount = this.items.length;
		this.classList.toggle('empty', !this.itemCount);
		this.hasLoaded = true;
		return this.sort();
	}

	public updateItem(item): Promise<void> {
		const existing = this.items.find(({rawData}) => getProperty(rawData, this.idColumn) === getProperty(item, this.idColumn));
		if (existing) {
			existing.update(item);
		} else {
			this.items.push(new Item(this, item));
		}
		this.itemCount = this.items.length;
		this.classList.toggle('empty', !this.itemCount);
		return this.sort();
	}

	private rawDataChanged(): void {
		if (this.hasLoaded) this.load();
	}
}
