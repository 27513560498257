import {bindable, viewResources, PLATFORM} from 'aurelia-framework';
import {ListEditorField} from 'form';
import {ListEditorItemTable} from './itemtable';
import {SelectedItem} from './selecteditem';
import {ItemEditorSaveResponse} from 'elements/itemeditor';
import {Price} from 'price';
import {ItemTableConfig, ItemTable} from 'elements/itemtable';

export type ListEditorValue = any[];

export interface ListEditorConfig {
	tableConfig: ItemTableConfig;
	showFooter?: boolean;
	maxSelection?: number;
	maxPrice?: Price;
	ThisSelectedItem?: typeof SelectedItem;
}

@viewResources(PLATFORM.moduleName('elements/listeditor/itemtable'))
export class ListEditor {
	@bindable public readonly field: ListEditorField;

	public isDirty: boolean = false;
	public availableItems: ListEditorItemTable;
	public selectedItems: SelectedItem[] = [];
	private itemTable: ItemTable;
	private config: ListEditorConfig;
	private isDragging: boolean = false;
	private endItem: SelectedItem = new SelectedItem(this);
	private selectedCount: string;
	private total?: string;
	private isAtMax: boolean = false;
	private element: HTMLElement;
	
	private attached(): void {
		this.field.listEditor = this;
		this.config = this.field.config;
	}

	private updateTotal(): void {
		if (!this.config.showFooter) return;
		const sel = this.selectedItems.length;
		const max = this.config.maxSelection;
		const total = Price.fromCents(this.selectedItems.reduce((total, item) => total + item.item.rawData.price.retail, 0));

		this.selectedCount = sel + (max ? ` / ${max}` : '') + ' Item' + ((max || sel) === 1 ? '' : 's');
		this.total = '$' + total.toString();
		this.isAtMax = max && sel >= max || false;
		this.itemTable.classList.toggle('no-links', this.isAtMax);
		this.element.classList.toggle('maxed', this.isAtMax);
		if (this.config.maxPrice) this.element.classList.toggle('price-maxed', total.cents >= this.config.maxPrice.cents);
	}

	public load(): void {
		if (this.field.originalValue) this.value = this.field.originalValue;
	}

	public changed(selectedOnly: boolean = false): void {
		this.isDirty = true;
		if (this.availableItems.search && !selectedOnly) this.availableItems.search.execute();
		if (this.field.onChange) this.field.onChange();
		this.updateTotal();
	}

	public saved(response: ItemEditorSaveResponse): void {
		this.isDirty = false;
		this.field.onChange();
	}

	public addItem(rawItem: any, isBatch: boolean = false) {
		if (!isBatch && this.config.maxSelection && this.selectedItems.length >= this.config.maxSelection) return;
		const ThisSelectedItem = this.config && this.config.ThisSelectedItem || SelectedItem;
		const selectedItem = new ThisSelectedItem(this, rawItem);
		const {item} = selectedItem;
		this.selectedItems.push(selectedItem);
		item.element.replaceWith(item.hiddenElement);
		const index = this.availableItems.items.indexOf(item);
		if (index >= 0) this.availableItems.items.splice(index, 1);
		if (!this.availableItems.search) this.availableItems.itemCount--;
		if (!isBatch) this.changed();
	}

	private removeItem(selectedItem: SelectedItem, isBatch: boolean = false) {
		const {item} = selectedItem;
		this.availableItems.items.push(item);
		item.hiddenElement.replaceWith(item.element);
		if (!isBatch) {
			const index = this.selectedItems.indexOf(selectedItem);
			if (index >= 0) this.selectedItems.splice(index, 1);
		}
		if (!this.availableItems.search) this.availableItems.itemCount++;
		if (!isBatch) this.changed();
	}

	private onDragstart(event: DragEvent, selectedItem: SelectedItem): boolean {
		event.dataTransfer.setData('text/plain', selectedItem.id);
		event.dataTransfer.dropEffect = 'move';
		this.isDragging = true;
		return true;
	}

	private onDragend(): void {
		this.isDragging = false;
		this.endItem.isTargeted = false;
		for (const item of this.selectedItems) {
			item.isTargeted = false;
			item.stopDragging();
		}
	}

	private onDragover(event: DragEvent): boolean {
		event.dataTransfer.effectAllowed = 'move';
		event.dataTransfer.dropEffect = 'move';
		event.preventDefault();
		return true;
	}

	private onDragenter(event: DragEvent, hoverItem: SelectedItem): void {
		this.endItem.isTargeted = this.endItem === hoverItem;
		for (const item of this.selectedItems) item.isTargeted = item === hoverItem;
	}

	private onDragleave(event: DragEvent, item: SelectedItem): void {
		item.isTargeted = false;
	}

	private onDrop(event: DragEvent, destItem: SelectedItem): void {
		this.onDragend();
		const sourceId = event.dataTransfer.getData('text/plain');
		const sourceIndex = sourceId && this.selectedItems.findIndex((item) => item.id === sourceId);
		const sourceItem = typeof sourceIndex === 'number' && this.selectedItems[sourceIndex];

		// No action if dropping back in the same spot
		if (!sourceItem || sourceItem === destItem || this.selectedItems[sourceIndex + 1] === destItem) return;
		if (destItem === this.endItem && sourceIndex >= this.selectedItems.length - 1) return;

		this.selectedItems.splice(sourceIndex, 1);
		if (destItem === this.endItem) {
			this.selectedItems.push(sourceItem);
		} else {
			const destIndex = this.selectedItems.indexOf(destItem);
			this.selectedItems.splice(destIndex, 0, sourceItem);
		}
		this.changed(true);
	}

	public get value(): ListEditorValue {
		return this.selectedItems.map((item) => item.value);
	}

	public set value(value: ListEditorValue) {
		this.isDirty = false;
		let selectedItem;
		while (selectedItem = this.selectedItems.pop()) this.removeItem(selectedItem, true);
		for (const rawItem of value) this.addItem(rawItem, true);
		if (this.availableItems.search) this.availableItems.search.execute();
		this.updateTotal();
	}
}
