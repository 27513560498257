import {WebService} from "webservice";
import {Route} from "route";
import {PLATFORM} from "aurelia-pal";
import {capitalize} from "utils";
import {NavigationInstruction} from "aurelia-router";

export class AppConfig {
	public static title: string;
	public static logo: string;
	public static mainLogo: string;
	public static env: string;
	public static auth: boolean;

	public static navIsOpen: boolean = false;
	public static signinRedirect?: NavigationInstruction;
	public static readonly routes: Route[] = [
		new Route(PLATFORM.moduleName('views/signin'), 'Sign In', false),
		new Route(PLATFORM.moduleName('views/home'), '', false, false, '/'),
		new Route(PLATFORM.moduleName('views/ship'), 'Package Order', false, true),
		new Route(PLATFORM.moduleName('views/signout'), 'Sign Out', false)
	];

	public static load(): Promise<void> {

		this.addItemRoutes(PLATFORM.moduleName('views/wineries'), PLATFORM.moduleName('views/winery'));
		this.addItemRoutes(PLATFORM.moduleName('views/products'), PLATFORM.moduleName('views/product'), undefined, undefined, true);
		this.addItemRoutes(PLATFORM.moduleName('views/packages'), PLATFORM.moduleName('views/package'));
		this.addItemRoutes(PLATFORM.moduleName('views/kits'), PLATFORM.moduleName('views/kit'));
		this.addItemRoutes(PLATFORM.moduleName('views/orders'), PLATFORM.moduleName('views/order'));
		this.addItemRoutes(PLATFORM.moduleName('views/members'), PLATFORM.moduleName('views/member'));
		this.addItemRoutes(PLATFORM.moduleName('views/blog'), PLATFORM.moduleName('views/blogpost'), 'Blog', 'Post');
		this.addItemRoutes(PLATFORM.moduleName('views/promos'), PLATFORM.moduleName('views/promo'));
		this.addItemRoutes(PLATFORM.moduleName('views/batches'), PLATFORM.moduleName('views/batch'), 'Process');

		return WebService.get('config').then((config) => {
			Object.assign(this, config.meta);
		}, (e) => {
			console.error('FATAL: Could not load config');
		});
	}

	public static addItemRoutes(tableModule: string, itemModule: string, tableTitle?: string, itemTitle?: string, allowSubroute: boolean = false): void {
		if (!itemTitle) itemTitle = capitalize(itemModule.replace(/^views\//, ''));
		if (!tableTitle) tableTitle = capitalize(tableModule.replace(/^views\//, ''));

		const tableRoute = new Route(tableModule, tableTitle, true);
		const itemRoute = new Route(itemModule, itemTitle, false, true, tableRoute.baseRoute, allowSubroute && '/:subroute?');
		this.routes.push(tableRoute, itemRoute);
	}

	public static toggleNav(): void {
		this.navIsOpen = !this.navIsOpen;
	}
}
