import {AppConfig} from '../../appconfig';
import {App} from '../../app';
import {autoinject} from 'aurelia-framework';
import {NavModel} from 'aurelia-router';

@autoinject
export class Header {
	public config: typeof AppConfig = AppConfig;
	public nav: NavModel[];

	constructor(public readonly app: App) {
		app.loadRouter.then((router) => {
			this.nav = router.navigation;
		});
	}

	public toggleNav() {
		this.config.toggleNav();
	}
}
