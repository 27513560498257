import {bindable, autoinject, PLATFORM, viewResources, useView} from 'aurelia-framework';
import {WebService, WebServiceResponse} from 'webservice';
import {App} from 'app';
import {Column} from './column';
import {SearchConfig, Search} from './search';
import {Item} from './item';
import {Filter, FilterConfig} from './filter';
import {Report} from './report';
import {DropdownConfig, DropdownOption} from 'elements/dropdown';
import {copyValue} from 'utils';
import {Route} from 'route';

export interface ItemTableConfig {
	readonly columns: (Column|string)[];
	readonly filters?: FilterConfig[];
	readonly reports?: (Report|DropdownOption)[];
	readonly search?: SearchConfig|boolean;
	readonly linkRoute?: string;
	readonly linkTarget?: string;
	readonly staticItems?: any[];
	readonly disabledProperty?: string;
	readonly errorProperty?: string;
	readonly parseItem?: (rawItem: any) => any;
	readonly createLinkTest?: (rawItem: any) => boolean;
}

@autoinject
@viewResources(PLATFORM.moduleName('elements/dropdown'))
@useView(PLATFORM.moduleName('elements/itemtable/index.html'))
export abstract class ItemTable {
	@bindable public element: HTMLDivElement;
	@bindable public tableElement: HTMLDivElement;

	public abstract readonly webService: string;
	public abstract readonly config: ItemTableConfig;
	
	protected readonly listAction: string = 'list';
	protected readonly titleTag: string = 'h1';
	protected readonly showTitle: boolean = true;
	protected readonly showNewButton: boolean = true;
	protected readonly showCopyButton: boolean = true;
	protected readonly showStatus: boolean = true;
	protected readonly showCount: boolean = true;
	protected readonly hideEmpty: boolean = false;
	public readonly isStatic: boolean = false;
	public readonly idColumn: string = '_id';
	public readonly createLinks: boolean = true;
	public readonly disabledProperty: string = 'isDisabled';
	public readonly errorProperty?: string;

	protected title: string;
	protected isLoading: boolean = true;
	protected filters: Filter[];
	protected defaultSort?: string;
	protected route: Route;
	private sortColumn: Column;
	private sortDescending: boolean = false;
	private reports: DropdownConfig;
	private newItemUrl: string;

	public classList?: DOMTokenList;
	public linkTarget: string = '_blank';
	public search: Search;
	public itemCount: number = 0;
	public isBusy: boolean = true;
	public holdBusy: boolean = false;
	public status: string = 'Loading';
	public items: Item[];
	public columns: Column[];

	protected parseItem?(rawItem: any): any;
	protected createLinkTest?(rawItem: any): boolean;
	public onItemClick?(item: Item, event: MouseEvent): void;
	private itemChanged?(event: CustomEvent): void;

	constructor(public readonly app: App) {}

	protected attached(): void {
		const {tableParams} = this;
		const {currentInstruction} = this.app.router;
		this.route = currentInstruction.config.settings.route;

		const sort = tableParams && tableParams.sort || this.defaultSort;
		const [, sortDirection, sortColumn] = sort && sort.match(/^([\+\-]?)(.*)$/) || [null,null,null];
		this.columns = this.config.columns.map((column) => column instanceof Column ? column : new Column(column));
		this.sortColumn = sortColumn && this.columns.find((column) => column.valueProperty === sortColumn) || this.columns[0];
		if (sortDirection && sortDirection === '-') this.sortDescending = true;

		if (this.config.search !== false) this.search = new Search(this, typeof this.config.search === 'boolean' ? undefined : this.config.search);
		if (this.config.filters && this.config.filters.length) this.filters = this.config.filters.map((filter) => new Filter(this, filter, tableParams && tableParams.filter));
		if (this.config.reports && this.config.reports.length) {
			this.reports = new DropdownConfig(
				this.config.reports.map((report) => report instanceof DropdownOption ? report : new DropdownOption(report.name, {link: report.url})),
				{isSelectable: false}
			);
			this.reports.options.unshift(new DropdownOption('Reports', {isDefault: true, isHidden: true}));
		}

		for (const property of ['idColumn', 'parseItem', 'createLinkTest', 'linkTarget', 'disabledProperty', 'errorProperty']) {
			if (this.config[property]) this[property] = this.config[property];
		}

		if (!this.title) this.title = this.webService.substring(0, 1).toUpperCase() + this.webService.substring(1);
		this.newItemUrl = `${this.route.baseRoute}/new`;

		this.classList = this.element.classList;
		if (!this.createLinks) this.classList.add('no-links');
		if (!this.showTitle) this.classList.add('no-title');
		if (this.hideEmpty) this.classList.add('hide-empty');

		if (!this.isStatic) {
			this.itemChanged = (event: CustomEvent): void => {
				if (event.detail === this.route.baseRoute) this.load();
			};
			window.addEventListener('sbItemChanged', this.itemChanged);
		}

		if (this.filters) {
			Promise.all(this.filters.map((filter) => filter.load)).then(() => {
				this.load();
			});
		} else {
			this.load();
		}
	}

	private detached(): void {
		if (!this.isStatic) window.removeEventListener('sbItemChanged', this.itemChanged);
	}

	public load(): Promise<void> {
		this.isLoading = true;
		this.isBusy = true;
		this.status = 'Loading';
		const {items} = this;
		if (items) delete this.items;
		const filters = this.filters && Object.assign({}, ...this.filters.map((filter) => filter.selectedOption.query));
		const promise = (this.config.staticItems ? Promise.resolve({success: true, items: this.config.staticItems} as WebServiceResponse) : WebService.post(`${this.webService}/${this.listAction}`, {filter: filters})).then(({items}) => {
			this.items = items.map((item) => {
				return new Item(this, this.parseItem ? this.parseItem(item) : item, this.createLinkTest && this.createLinkTest(item));
			}).filter((item) => {
				return this.columns.find((column) => !!item.values[column.valueProperty].rawValue);
			});
			this.itemCount = this.items.length;
			this.isLoading = false;
			this.classList.toggle('empty', !this.itemCount);
			return this.sort();
		});
		if (items) for (const item of items) item[item.isHidden ? 'hiddenElement' : 'element'].remove();
		return promise;
	}

	public updateParams(params: any): boolean {
		if (this.isLoading) return false;
		const {queryParams, tableParams} = this;
		Object.assign(tableParams, params);
		Object.assign(queryParams, {[this.webService]: tableParams});
		this.app.router.navigateToRoute(this.app.currentRoute, Object.assign(queryParams, this.routeParams), {trigger: false, replace: true});
		return true;
	}

	protected sort(): Promise<void> {
		this.isBusy = true;
		this.status = 'Sorting';
		const start = performance.now();

		for (const column of this.columns) {
			column.sortClass = this.sortColumn === column ? `fa-sort-${this.sortDescending ? 'desc' : 'asc'}` : '';
		}

		return (new Promise((resolve) => {
			setTimeout(() => {
				const dir: [number, number] = this.sortDescending ? [1, -1] : [-1, 1];
				this.items.sort((itemA, itemB) => {
					const a = itemA.values[this.sortColumn.valueProperty].rawValue;
					const b = itemB.values[this.sortColumn.valueProperty].rawValue;
					if (a == b) return 0;
					if (typeof a === 'string' && typeof b === 'string') return this.sortDescending ? b.localeCompare(a) : a.localeCompare(b);
					return a < b ? dir[0] : dir[1];
				});
				resolve();
			}, 0);
		})).then(() => {
			console.info(`Sorting completed in ${performance.now() - start}ms`);
			this.tableElement.append(...this.items.map((item) => item.isHidden ? item.hiddenElement : item.element));
			if (!this.holdBusy) this.isBusy = false;
		});
	}

	public sortBy(column: Column): void {
		if (this.isBusy) return;
		if (this.sortColumn === column) {
			this.sortDescending = !this.sortDescending;
		} else {
			this.sortColumn = column;
			this.sortDescending = false;
		}
		this.updateParams({sort: this.sortString});
		this.sort();
	}

	private copyData(): void {
		const {items, columns} = this;
		copyValue(columns.map(({title}) => title).join('\t') + '\n' + (items && items.map((item) => {
			return columns.map(({valueProperty}) => item.values[valueProperty].stringValue).join('\t');
		}).join('\n')));
	}

	public get routeParams(): any {
		return this.app.router.currentInstruction.params;
	}
	public get queryParams(): any {
		return this.app.router.currentInstruction.queryParams;
	}
	public get tableParams(): any {
		return this.queryParams && this.queryParams[this.webService] || {};
	}
	public get sortString(): string {
		return (this.sortDescending ? '-' : '') + this.sortColumn.valueProperty;
	}
}
