import {bindable, PLATFORM} from 'aurelia-framework';
import {DialogContainer} from 'elements/dialog/container';
import {WebService} from 'webservice';
import {Dialog} from 'elements/dialog';

interface WalletConfig {
	customerId: string;
	onSelect: (card: CardData) => void;
}

interface CardData {
	token?: string;
	cardholderName?: string;
	cardType?: string;
	last4?: string;
}

export class Wallet {
	@bindable private readonly config: WalletConfig;
	@bindable private readonly dialog: Dialog;
	private error: string;
	private isLoading: boolean = true;
	private creditCards: CardData[];
	private defaultCard: CardData;

	private attached(): void {
		if (!this.config.customerId) {
			this.error = 'Could not load wallet - missing customer ID';
			this.isLoading = false;
			return;
		}
		WebService.post('customers/creditCards', {id: this.config.customerId}).then((response) => {
			this.creditCards = response.creditCards;
		}, (error) => {
			this.error = error;
		}).finally(() => {
			this.isLoading = false;
		});
	}

	private select(card: CardData): void {
		if (this.dialog) this.dialog.close();
		if (this.config.onSelect) this.config.onSelect(card);
	}

	public static openDialog(dialogContainer: DialogContainer, config: WalletConfig): void {
		dialogContainer.open(PLATFORM.moduleName('elements/wallet/dialog.html'), {config}, {
			heading: 'Wallet',
			buttons: []
		});
	}
}
