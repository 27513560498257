import {bindable} from "aurelia-framework";
import {DropdownOption} from ".";

export class DropdownItem {
	@bindable option: DropdownOption;
	@bindable element: HTMLAnchorElement;

	private attached(): void {
		this.option.element = this.element as HTMLAnchorElement;
	}
}
