import {padNumber} from "utils";
import {observable} from "aurelia-binding";
import {DatePicker} from ".";
import {DateFormatter} from "dateFormatter";

export class Time {
	public readonly sections: TimeSection[];
	public readonly date: Date;

	constructor(public readonly datePicker: DatePicker) {
		this.date = this.datePicker.date;
		this.sections = [
			new HourSection(this),
			new MinuteSection(this),
			new AmPmSection(this)
		];
	}
}

abstract class TimeSection {
	@observable public value: string;
	protected abstract validValues: string[];
	protected abstract resetValue(): void;
	protected acceptInput: boolean = true;

	constructor(protected readonly time: Time) {
		this.resetValue();
	}

	private focus(event: FocusEvent): void {
		if (this.acceptInput) (event.target as HTMLInputElement).select();
	}

	private change(increment: -1|1): void {
		this.resetValue();
		const max = this.validValues.length - 1;
		let index = this.validValues.indexOf(this.value);
		index += increment;
		this.value = this.validValues[index < 0 ? max : (index > max ? 0 : index)];
	}

	protected valueChanged(): void {
		this.time.datePicker.dateChanged();
	}
}

class HourSection extends TimeSection {
	protected validValues = [];

	constructor(time: Time) {
		super(time);
		for (let i = 1; i <= 12; i++) this.validValues.push(i.toString());
	}

	protected valueChanged(): void {
		const value = parseInt(this.value);
		if (this.validValues && this.validValues.includes(value.toString())) {
			this.time.date.setHours(DateFormatter.convertTo24(value, this.time.date.getHours() < 12 ? 'am' : 'pm'));
		}
		super.valueChanged();
	}

	protected resetValue(): void {
		const {date} = this.time;
		this.value = date ? DateFormatter.convertTo12(date.getHours()).toString() : '12';
	}
}

class MinuteSection extends TimeSection {
	protected validValues = [];

	constructor(time: Time) {
		super(time);
		for (let i = 0; i <= 59; i++) this.validValues.push(padNumber(i));
	}

	protected valueChanged(): void {
		const value = parseInt(this.value);
		if (this.validValues && this.validValues.includes(padNumber(value))) {
			this.time.date.setMinutes(value);
		}
		super.valueChanged();
	}

	protected resetValue(): void {
		const {date} = this.time;
		this.value = date ? padNumber(date.getMinutes()) : '00';
	}
}

class AmPmSection extends TimeSection {
	public value: 'am'|'pm';
	protected validValues = ['am', 'pm'];
	protected acceptInput = false;

	protected resetValue(): void {
		const {date} = this.time;
		this.value = !date || date.getHours() < 12 ? 'am' : 'pm';
	}

	protected valueChanged(): void {
		if (this.validValues && this.validValues.includes(this.value)) {
			this.time.date.setHours(
				DateFormatter.convertTo24(
					DateFormatter.convertTo12(this.time.date.getHours()),
					this.value
				)
			);
		}
		super.valueChanged();
	}
}
