import {ItemTable} from ".";
import {Column} from "./column";
import {getProperty} from "utils";

export class ItemValue<T> {
	private displayValue: string;
	public readonly rawValue: T
	public readonly value: string;
	public readonly stringValue: string;
	public readonly normalizedValue: string;
	public readonly element: HTMLDivElement = document.createElement('div');

	constructor(public readonly item: Item, private readonly column: Column, rawData: any) {
		this.element.className = column.hideClass;
		this.rawValue = column.initializeValue(rawData);
		this.value = column.parseValue(this);
		this.stringValue = column.parseStringValue(this);
		this.normalizedValue = this.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
		this.resetText();
	}
	public resetText(): void {
		this[this.column.isHtml ? 'setHtml' : 'setText'](this.value);
	}
	public setText(value: string): void {
		if (this.displayValue !== value) this.element.innerText = this.displayValue = value;
	}
	public setHtml(value: string): void {
		if (this.displayValue !== value) this.element.innerHTML = this.displayValue = value;
	}
}

export class Item {
	public readonly hiddenElement: HTMLSpanElement = document.createElement('span');
	public readonly element: HTMLAnchorElement;
	public readonly values: {[index: string]: ItemValue<any>} = {};
	public isHidden: boolean = false;

	constructor(private readonly table: ItemTable, public readonly rawData: any, createLink: boolean = true) {
		this.element = document.createElement('a');
		if (this.table.createLinks && createLink) {
			if (this.table.onItemClick) {
				this.element.addEventListener('click', (event) => {
					this.table.onItemClick(this, event);
				});
			} else {
				this.element.href = `${this.table.config.linkRoute || this.table.app.currentRoute}/${getProperty(rawData, this.table.idColumn)}`;
				this.element.target = this.table.linkTarget;
				this.element.rel = 'opener';
			}
		}
		this.checkFlags(rawData);
		for (const column of this.table.columns) {
			this.values[column.valueProperty] = new ItemValue(this, column, rawData);
			this.element.append(this.values[column.valueProperty].element);
		}
	}

	public update(rawData: any) {
		this.checkFlags(rawData);
		for (const column of this.table.columns) {
			if (getProperty(rawData, column.valueProperty) === undefined) continue;
			const oldElement = this.values[column.valueProperty].element;
			this.values[column.valueProperty] = new ItemValue(this, column, rawData);
			oldElement.replaceWith(this.values[column.valueProperty].element);
		}
	}

	private checkFlags(rawData: any) {
		const {disabledProperty, errorProperty} = this.table;
		this.element.classList.toggle('disabled', !!getProperty(rawData, disabledProperty));
		if (!errorProperty) return;
		this.element.classList.toggle('error', !!getProperty(rawData, errorProperty));
	}
}
