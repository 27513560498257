import {bindable, observable} from "aurelia-framework";

export class Address {
	@bindable @observable private address: AddressData|string;
	@bindable private hideName: boolean = false;
	private lines: string[];
	private icon: string;
	private iconTitle: string;
	private isEmpty: boolean = true;

	private addressChanged(): void {
		const a = this.address;
		this.isEmpty = true;
		this.lines = [];

		if (!a || typeof a === 'string') return;

		this.isEmpty = false;

		const name = a.firstName + ' ' + a.lastName;
		const lastLine = a.locality + ', ' + a.region + ' ' + a.postalCode;

		if (!this.hideName) {
			if (a.firstName || a.lastName) this.lines.push(name.trim());
			if (a.company) this.lines.push(a.company);
		}
		this.lines.push(a.streetAddress);
		if (a.extendedAddress) this.lines.push(a.extendedAddress);
		this.lines.push(lastLine);
		if (a.phone) this.lines.push(a.phone);

		this.icon = a.isBusiness ? 'building' : 'home';
		this.iconTitle = a.isBusiness ? 'Business' : 'Residential';
	}

	public get stringValue(): string {
		return this.lines.join('\n');
	}
}

export interface AddressData {
	_id: string;
	firstName: string;
	lastName: string;
	company?: string;
	streetAddress: string;
	extendedAddress?: string;
	locality: string;
	region: string;
	postalCode: string;
	phone: string;
	isBusiness: boolean;
	customerAddress?: AddressData;
	isDefault?: boolean;
	isSelected?: boolean;
}
