import {Price} from "../../price";
import {ItemValue} from "./item";
import {getProperty} from "utils";
import {DateFormatter} from "dateFormatter";

type Breakpoint = 'xl'|'lg'|'md'|'sm'|'xs';

export class Column {
	public readonly valueProperty: string;
	public readonly hideClass: string;
	public readonly showTitle: boolean = true;
	public readonly isHtml: boolean = false;
	public readonly icon?: string;
	public sortClass: string = '';

	constructor(public readonly title: string, valueProperty?: string, hideBreakpoint?: Breakpoint, public readonly defaultValue: any = '') {
		this.valueProperty = valueProperty || title.toLowerCase().replace(/\s+\w/g, (match) => match.replace(/\s+/, '').toUpperCase());
		this.hideClass = hideBreakpoint ? `no-${hideBreakpoint}` : '';
	}
	public initializeValue(rawData: any): any {
		const value = getProperty(rawData, this.valueProperty);
		return value === undefined || value === null ? this.defaultValue : value;
	}
	public parseValue({rawValue}: ItemValue<any>): string {
		return rawValue ? rawValue.toString() : '-';
	}
	public parseStringValue({value}: ItemValue<any>): string {
		return value;
	}
}

export class ConcatColumn extends Column {
	public readonly valueProperty: string;

	constructor(title: string, public readonly valueProperties: string[], hideBreakpoint?: Breakpoint, private readonly separator: string = ', ') {
		super(title, null, hideBreakpoint);
		this.valueProperty = '__$' + this.valueProperty;
	}
	public initializeValue(rawData: any): string {
		const value = this.valueProperties.map((property) => getProperty(rawData, property)).join(this.separator);
		return value === undefined || value === null ? this.defaultValue : value;
	}
}

export class BooleanColumn extends Column {
	public readonly defaultValue: boolean = false;

	public parseValue({rawValue}: ItemValue<boolean>): string {
		return rawValue ? 'Yes' : 'No';
	}
}

export class IconColumn extends BooleanColumn {
	public readonly isHtml = true;
	public readonly showTitle = false;

	constructor(title: string, valueProperty?: string, private readonly trueIcon?: string, private readonly falseIcon?: string, public readonly icon: string = trueIcon, hideBreakpoint?: Breakpoint) {
		super(title, valueProperty, hideBreakpoint);
	}

	public parseValue({rawValue}: ItemValue<boolean>): string {
		if (rawValue && this.trueIcon) return `<span class="fa fa-${this.trueIcon}"></span>`;
		if (!rawValue && this.falseIcon) return `<span class="fa fa-${this.falseIcon}"></span>`;
		return '';
	}

	public parseStringValue(itemValue: ItemValue<boolean>): string {
		return super.parseValue(itemValue);
	}
}

export class EnumColumn extends Column {
	constructor(title: string, valueProperty: string, private readonly stringMap: {[index: string]: string}, hideBreakpoint?: Breakpoint) {
		super(title, valueProperty, hideBreakpoint);
	}

	public parseValue({rawValue}: ItemValue<string>): string {
		return rawValue && this.stringMap[rawValue] || '-';
	}
}

export class DateColumn extends Column {
	public readonly defaultValue: Date = null;

	constructor(title: string, valueProperty?: string, hideBreakpoint?: Breakpoint, public readonly dateFormatter: DateFormatter = new DateFormatter()) {
		super(title, valueProperty, hideBreakpoint);
	}

	public initializeValue(rawData: any): Date {
		const value: string = super.initializeValue(rawData);
		if (!value) return null;
		return new Date(value);
	}

	public parseValue({rawValue}: ItemValue<Date>): string {
		return rawValue ? this.dateFormatter.format(rawValue) : 'No';
	}
}

export class PriceColumn extends Column {
	public readonly defaultValue: number = 0;

	public parseValue({rawValue}: ItemValue<number>): string {
		return rawValue ? '$' + Price.fromCents(rawValue).toString() : '-';
	}
}

export class CountColumn extends Column {
	public readonly defaultValue: number = 0;

	constructor(
		title: string,
		valueProperty?: string,
		public readonly collectionProperty: string = 'products',
		public readonly itemAccumulatorProperty: string = 'quantity',
		private readonly processTotal?: (total: number, rawData: any, collection: any[]) => number,
		hideBreakpoint?: Breakpoint
	) {
		super(title, valueProperty, hideBreakpoint);
	}

	public initializeValue(rawData: any): number {
		const value = super.initializeValue(rawData);
		if (value) return value;

		const collection = getProperty(rawData, this.collectionProperty) as any[];
		if (!collection) return value;

		const total = collection.reduce((total: number, item: any): number => {
			return total + (getProperty(item, this.itemAccumulatorProperty) || 0);
		}, 0);

		return this.processTotal ? this.processTotal(total, rawData, collection) : total;
	}
}

export class SubtotalColumn extends CountColumn {
	constructor(
		title: string,
		valueProperty?: string,
		collectionProperty?: string,
		itemAccumulatorProperty: string = 'price.retail',
		processTotal?: (total: number, rawData: any, collection: any[]) => number,
		hideBreakpoint?: Breakpoint
	) {
		super(title, valueProperty, collectionProperty, itemAccumulatorProperty, processTotal, hideBreakpoint);
	}
}
SubtotalColumn.prototype.parseValue = PriceColumn.prototype.parseValue;


export class RatingColumn extends Column {
	public readonly defaultValue: number = 0;
	public readonly isHtml = true;

	public parseValue({rawValue}: ItemValue<number>): string {
		let html = '<div class="rating">';
		for (let star = 1; star <= 5; star++) {
			html += `<span class="fa fa-star${star > rawValue ? '-o' : ''}"></span>`;
		}
		return html + '</div>'
	}

	public parseStringValue({rawValue}: ItemValue<number>): string {
		return rawValue.toString();
	}
}
