import {bindable, autoinject, noView} from 'aurelia-framework';
import {RichTextField} from 'form';

// TinyMCE Core
import * as TinyMCE from 'tinymce';

// Theme
import 'tinymce/themes/silver/theme';

// Skin
import 'tinymce/skins/ui/oxide/skin.min.css';

// Plugins
import 'tinymce/plugins/quickbars/plugin';
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/code/plugin';
import {ItemEditorSaveResponse} from 'elements/itemeditor';

interface Settings extends TinyMCE.Settings {
	quickbars_selection_toolbar?: string;
	quickbars_insert_toolbar?: string;
	contextmenu?: string;
	image_dimensions?: boolean;
	image_advtab?: boolean;
	image_class_list?: {title: string, value: string}[];
}

@autoinject
@noView
export class RichText {
	@bindable private field: RichTextField;
	private element: HTMLDivElement;
	private editor: TinyMCE.Editor;
	private placeholder?: HTMLDivElement;

	constructor(element: Element) {
		this.element = element as HTMLDivElement;
	}

	private attached(): void {
		this.field.richText = this;

		const settings: Settings = {
			target: this.element,
			inline: true,
			skin: false as any,
			plugins: ['quickbars', 'paste', 'link', 'lists', 'image', 'media', 'code'],
			toolbar: false,
			menubar: false,
			quickbars_selection_toolbar: 'h1 h2 | bold italic underline | bullist numlist | alignleft aligncenter alignright | quicklink image | removeformat',
			quickbars_insert_toolbar: 'h1 h2 | image media',
			contextmenu: 'undo redo code',
			image_dimensions: false,
			image_class_list: [
				{title: 'Full Size', value: ''},
				{title: 'Large', value: 'lg'},
				{title: 'Medium', value: 'md'}
			]
		};

		(TinyMCE.init(settings) as any).then(([editor]) => {
			this.editor = editor;
			if (this.field.originalValue) this.value = this.field.originalValue;
			this.checkEmpty();
			for (const event of ['input', 'change', 'execcommand', 'blur']) this.editor.on(event, () => {
				this.checkEmpty();
				if (this.field.onChange) this.field.onChange();
			});
			if (this.field.hasPlaceholder) {
				this.placeholder = document.createElement('div');
				this.placeholder.className = 'placeholder';
				this.placeholder.innerText = this.field.placeholder;
				this.element.after(this.placeholder);
			}
		});
	}

	public saved(response: ItemEditorSaveResponse): void {
		this.editor.save({});
		this.field.onChange();
	}

	private checkEmpty(): void {
		this.element.classList.toggle('empty', !this.editor.getContent().trim());
	}

	public get isDirty(): boolean {
		return this.editor && this.editor.isDirty();
	}

	public get value(): string {
		return this.editor.getContent();
	}
	public set value(value: string) {
		this.editor.setMode('readonly');
		this.editor.setContent(value);
		this.field.originalValue = this.value;
		this.editor.save({});
		this.editor.setMode('design');
	}
}
