import {bindable} from 'aurelia-framework';
import {DialogContainer} from './container';


abstract class Button {
	public abstract icon: string;
	public abstract label: string;
	public abstract action: (dialog: Dialog) => void;
}


export class DialogButton extends Button {
	constructor(
		public icon: string,
		public label: string,
		public action: (dialog: Dialog) => void
	) {
		super();
	}
}

export class OkButton extends Button {
	public icon: string = 'check-circle';
	public label: string = 'OK';

	constructor(
		public action: (dialog: Dialog) => void = (dialog): void => {
			dialog.close();
		}
	) {
		super();
	}
}

export class YesButton extends OkButton {
	public label: string = 'Yes';
}

export class CancelButton extends Button {
	public icon: string = 'ban';
	public label: string = 'Cancel';

	constructor(
		public action: (dialog: Dialog) => void = (dialog): void => {
			dialog.close();
		}
	) {
		super();
	}
}

export class NoButton extends CancelButton {
	public label: string = 'No';
}

export interface DialogConfig {
	bodyView?: string;
	bodyModel?: {
		dialog: Dialog;
		[index: string]: any;
	};
	heading?: string;
	buttons?: DialogButton[];
	cssClass?: string;
	hideCloseButton?: boolean;
}

export class Dialog {
	@bindable private readonly container: DialogContainer;
	@bindable private readonly config: DialogConfig;

	private attached(): void {
		this.config.bodyModel.dialog = this;
	}

	public close(): void {
		this.container.close();
	}

	public closeAll(): void {
		this.container.close(true);
	}
}
