import {observable} from 'aurelia-binding';
import {bindable} from 'aurelia-framework';
import {Item} from 'elements/itemtable/item';
import {ListEditor} from '.';

export class SelectedItem {
	@bindable public readonly target: HTMLDivElement;
	@bindable public readonly element: HTMLLIElement;

	private _isTargeted: boolean = false;

	protected hasDescription: boolean = false;
	protected link?: string;
	public readonly id: string;
	public readonly item: Item;
	public label: string;
	
	constructor(protected readonly listEditor: ListEditor, item?: string|Item, protected readonly nameField: string = 'name') {
		if (!item) return;
		const {items, idColumn} = listEditor.availableItems;
		this.item = item instanceof Item ? item : items.find((tableItem) => tableItem.rawData[idColumn] === item);
		if (this.item) {
			this.id = this.item.rawData[idColumn];
			this.label = this.item.values[this.nameField] && this.item.values[this.nameField].value;
		}
	}

	public startDragging(): boolean {
		this.element.setAttribute('draggable', 'true');
		return true;
	}

	public stopDragging(): boolean {
		this.element.removeAttribute('draggable');
		return true;
	}

	public get isTargeted(): boolean {
		return this._isTargeted;
	}

	public set isTargeted(value: boolean) {
		this._isTargeted = value;
		this.target.classList.toggle('active', value);
	}

	public get value(): any|string {
		return this.id;
	}
}

export class SelectedProduct extends SelectedItem {
	constructor(listEditor: ListEditor, item: string|Item) {
		super(listEditor, item, 'name');
		if (this.item.rawData.year) this.label += ` (${this.item.values.year.value})`;
		this.link = `/products/${this.id}`;
	}
}

export class SelectedProductExtended extends SelectedItem {
	constructor(listEditor: ListEditor, item: string|Item) {
		super(listEditor, item, 'product.name');
		if (this.item.rawData.product.year) this.label += ` (${this.item.values['product.year'].value})`;
		this.link = `/products/${this.id}`;
	}
}

interface ProductData {
	_id: string;
	product: string;
	description?: string;
}
export class SelectedProductWithDescription extends SelectedProduct {
	@observable private description: string;
	private readonly originalDescription: string;
	private _id?: string;

	constructor(listEditor: ListEditor, item: ProductData|Item) {
		if (item instanceof Item) {
			super(listEditor, item);
		} else {
			super(listEditor,  item.product);
			this._id = item._id;
			this.originalDescription = item.description || '';
			this.description = this.originalDescription;
		}
		
		this.hasDescription = true;
	}

	private descriptionChanged(): void {
		if (this.description !== this.originalDescription) this.listEditor.changed(true);
	}

	public get value(): ProductData {
		return {_id: this._id, product: this.id, description: this.description};
	}
}
