interface SegmentDefinition {
	_id: string,
	count: number
}

export class SegmentMapping {
	constructor(public readonly id: string, public readonly name: string, public readonly link?: string) {}
}

export class Segment extends SegmentMapping {
	public readonly count: number;
	public readonly ratio: number;

	constructor(map: SegmentMapping, definitions: SegmentDefinition[], {total}: Chart) {
		super(map.id, map.name, map.link);
		const {count} = definitions.find((segment) => segment._id === this.id);
		this.count = count;
		this.ratio = count / total;
	}
}

export class Chart {
	public readonly segments: Segment[];
	public readonly total: number;
	public readonly max: number;

	constructor(public readonly title: string, public readonly totalLabel: string, definitions: SegmentDefinition[], segmentMaps: SegmentMapping[]) {
		this.max = Math.max(...definitions.map((segment) => segment.count));
		this.total = definitions.reduce((total, segment) => total + segment.count, 0);
		this.segments = segmentMaps.map((map) => new Segment(map, definitions, this));
	}
}
