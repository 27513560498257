import {bindable, viewResources, PLATFORM} from "aurelia-framework";
import {Field as FormField} from "../../form";

@viewResources(
	PLATFORM.moduleName('elements/address'),
	PLATFORM.moduleName('elements/dropdown'),
	PLATFORM.moduleName('elements/upload'),
	PLATFORM.moduleName('elements/richtext'),
	PLATFORM.moduleName('elements/listeditor'),
	PLATFORM.moduleName('elements/datepicker'),
	PLATFORM.moduleName('elements/itemtable/static')
)
export class Field {
	@bindable public field: FormField;

	private attached(): void {
		if (this.field.attached) this.field.attached();
	}
}
